import Vue from 'vue'
import Vuex from 'vuex'
import {
  mutations
} from './mutations'
import * as actions from './actions'
import VuexPersistence from "vuex-persist";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: "adee-recom-storage", // The key to store the state on in the storage provider.
  storage: window.localStorage//, // or window.sessionStorage or localForage
  // modules: ["todo", "user"], // store all variables in module in store
});

const state = {
  user: {
    id: '',
    login: '',
    token: '',
    profil: '',
    fullLogged: false
  },
  dptSelect: [],
  search: '',
  sortorder: '',
  sort: '',
}


export default new Vuex.Store({
  state,
  mutations,
  actions,
  plugins: [vuexLocal.plugin]
})
