<template>
  <!-- CONTENT -->
  <section class="">
    <div class="">
      Page Principale ADEE - ReCom
    </div>
  </section>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  data() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
