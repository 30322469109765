<template>
  <div id="app">
    <router-view/>
    <notifications group="general-notification" position="top right" width="400" animation-type="velocity"
                   :duration="4000"/>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {}
}
</script>

<style>
.vue-notification-template.vue-notification {
  padding: 20px;
  height: 100px;
  font-size: 18px;
  font-weight: 900;
  color: #ffffff;
  background: #44A4FC;
  border-left: 10px solid #187FE7;

&
.warn {
  background: $ orange;
  border-left-color: #f48a06;
}

&
.error {
  background: #E54D42;
  border-left-color: #B82E24;
}

&
.success {
  background: #68CD86;
  border-left-color: #42A85F;
}

}
</style>
